// ** Redux Imports
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// window list
export const getAllData = createAsyncThunk(
  "Dashboard/getAllData",
  async () => {
    const response = await axios.get("/dashboard")
    return response.data
  }
)

export const DashboardSlice = createSlice({
  name: "Dashboard",
  initialState: {
    allData: {}
  },
  extraReducers: (builder) => {
    builder.addCase(getAllData.fulfilled, (state, action) => {
      state.allData = action.payload
    })
  }
})

export default DashboardSlice.reducer
