import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'

// subscriptions list
export const getAllData = createAsyncThunk('subscriptions/getAllData', async () => {
  const response = await axios.get('/subscriptions')
  return response.data
})

export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState: {
    permission: {},
    data: [],
    loading: false
  },
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {        
        state.data = action.payload?.data?.subscriptions            
      }) 
      
  }
})
export const { setLoading } = subscriptionsSlice.actions

export default subscriptionsSlice.reducer
